$primary: #DD1D21;
$secondary: #FBCE07;
$dark: #0d0e10;
$white: white;
$activated: #5bc0de;
$cancelled: #dfbf35;
$expired: #df3936;
$done: #a1ce31;
$disabled: #dee2e6;

.bg-primary{
  background-color: $primary!important;
  color: white!important;
}

.bg-secondary{
  background-color: $secondary!important;
  color: $white!important;
}

.bg-dark{
  background-color: $dark!important;
  color: $white!important;
}